.attention-modal .ant-modal-title {
    color: #d71f0f;
    text-align: center;
}

.attention-modal .ant-modal-body {
    padding: 5px 5px 10px 10px;
}

.attention-modal .ant-modal-header {
    border-bottom-color: #d71f0f;
}

.attention-modal .ant-modal-title {
    font-size: 1.1rem;
}
.attention-modal .ant-modal-bottom-title {
    color: #d71f0f;
    text-align: center;
    font-size: .9rem;
    margin-bottom: 0;
}
.attention-modal .ant-modal-footer {
    border-top-color: #d71f0f;
}
.attention-modal .ant-modal-close {
    color: #d71f0f;
}

.read-more {
    color: #ffffff;
    background-color: #d71f0f;
    border-color: #d71f0f;
}