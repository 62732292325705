.accept-rules-card {
    padding: 25px 25px;
}

.ant-card-bordered.accept-rules-card {
    border: 1px solid #d71f0f;
}

.accept-rules-card .ant-checkbox-wrapper {
    color: #d71f0f;
    font-size: 16px;
}

.accept-rules-card h4 {
    font-size: 16px;
}
.accept-rules-card .ant-checkbox-inner {
    border: 1px solid #d71f0f;
}

.accept-rules-card .file-rules {
    color: #d71f0f;
    font-size: 16px;
}

.accept-rules-card .welcome p {
    font-size: 16px;
}