.app-shipping .top-nav {
    height: 28px;
}
.app-shipping .top-nav .top-nav-wrapper .ant-menu-horizontal {
    height: 28px;
    line-height: 22px;
    text-align: center;
    text-transform: uppercase;
}

.app-shipping .top-nav .top-nav-wrapper .ant-menu-horizontal li {
    vertical-align: text-top;
    border: 1px solid transparent;
    padding-left: 15px;
    padding-right: 15px;
}


.app-shipping .top-nav .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.app-shipping .top-nav .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.app-shipping .top-nav .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.app-shipping .top-nav .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.app-shipping .top-nav .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.app-shipping .top-nav .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    border: 1px solid transparent;
}
.app-shipping .ant-menu-horizontal > .ant-menu-item,
.app-shipping .ant-menu-horizontal > .ant-menu-submenu {
    border-bottom: none;
}


.app-shipping .top-nav .top-nav-wrapper .ant-menu-horizontal li.ant-menu-item-selected,
.app-shipping .top-nav .top-nav-wrapper .ant-menu-horizontal li.ant-menu-submenu-selected {
    border: 1px solid white;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: .4rem;
}

.app-shipping .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.app-shipping .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.app-shipping .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.app-shipping .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.app-shipping .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.app-shipping .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.app-shipping .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected,
.app-shipping .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover {
    color: rgba(255, 255, 255, 1);
}



.app-shipping .ant-switch-checked {
    background-color: #d71f0f;
}
.app-shipping .ant-spin-dot-item {
    background-color: #d71f0f;
}
.app-shipping .ant-spin-dot {
    /*background-color: #d71f0f;*/
    color: #d71f0f;
}

.app-shipping .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.app-shipping .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
    margin-top: -2px;
}

.app-shipping .ant-card-head {
    color: #d71f0f;
}

.app-shipping .ant-pagination-item-active {
    background: #d71f0f;
}

.app-shipping .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #d71f0f;
}
.app-shipping .ant-tabs-tab:hover {
    color: #d71f0fe8;
}

.app-shipping .nav .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
    border-bottom: 0;
}
.app-shipping .nav .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover {
    border-bottom: 0;
}

.loading.cover-content {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.loading.cover-page {
    position: fixed;
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
}
.app-shipping .loading .ant-spin {
    color: #d71f0f;
}

.app-shipping .ant-btn-link {
    color: #d71f0f;
}

.button-as-text.ant-btn-link {
    color: inherit;
    font-weight: inherit;
}

th.column-total,
td.column-total {
    font-weight: bold;
}
th.column-total-fill,
td.column-total-fill {
    background-color: rgba(179, 179, 179, 0.11) !important;
}
.t-vertical-align {
    display: flex;
    align-items: center;
    justify-content: center;
}
.push {
    margin-left: auto !important;
}
.ant-table-tbody > tr.ant-table-row.row-selected > td {
    background: rgb(250, 250, 250);
}

.shipping-table .ant-table tfoot>tr>td,
.shipping-table .ant-table tfoot>tr>th {

}
.shipping-table .ant-table-tbody>tr>td {
    padding: 5px;
}
.shipping-table .ant-table-thead>tr>th {

}
.t-center {
    text-align: center;
}
.spin-center {
    margin-top: 20px;
    margin-right: 0;
    margin-bottom: 20px;
    margin-left: 0;
    padding: 30px 50px;
    text-align: center;
    background: transparent;
    border-radius: 4px;
}
.spin-center-fixed {
    position: fixed;
    top: 50%;
    left: 50%;
}

.add-pay {
    width: 100%;
}
.add-pay .autohide {
    visibility: hidden;
}
.add-pay:hover .autohide {
    visibility: visible;
}


tbody.ant-table-tbody > tr > td {
    padding-top: 3px;
    padding-bottom: 3px;
}
tfoot.ant-table-summary > tr > td {
    padding-top: 3px;
    padding-bottom: 3px;
}

.ant-table .cell-space-between {
    display: flex;
    justify-content: space-between;
}

.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 1.6em;
    background-color: rgba(0,0,0,.06);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: background-color .3s;
    content: '';
}

table > .ant-table-thead > tr > th {
    text-align: center;
    text-transform: uppercase;
}
.ant-table-thead > tr > th.table-col-selected {
    /*background: rgba(141, 156, 189, 0.1);*/
    background: #f1f1f1;
    border-color: rgba(0, 0, 0, 0.03);
}
.ant-table-tbody td.table-col-selected {
    /*background: rgba(141, 156, 189, 0.1);*/
    background: #f1f1f1;
    border-color: rgba(0, 0, 0, 0.03);
}

.ant-table-tbody > tr.table-row-selected > td {
    /*background: rgba(141, 156, 189, 0.1);*/
    background: #f1f1f1;
    border-color: rgba(0, 0, 0, 0.03);
}




.ant-table-tbody>tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 1.6em;
    background-color: rgba(0,0,0,.06);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: background-color .3s;
    content: '';
}

.ant-table-tbody>tr>td {
    max-width: 240px;
    white-space: nowrap;
    overflow-x: clip;
    text-overflow: ellipsis;
}

.ant-table-tbody > tr > td .align-number-group {
    text-align: right;
    padding-right: 5px;
    padding-left: 5px;
    min-width: 105px;
}
.ant-table-tbody > tr > td.align-number {
    text-align: right;
    padding-right: 10px !important;
}
.p-lr-5 {
    padding-left: 5px;
    padding-right: 5px;
}
.ant-tag-mr0 .ant-tag {
    margin-right: 0;
}

.ant-form-item .ant-input-number-handler-wrap {
    display: none;
}

.app-shipping .checkbox-enabled .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #d71f0f;
    border-color: #d71f0f !important;
}
.ant-modal-root .ant-checkbox-disabled .ant-checkbox-inner {
    /*background-color: #d71f0f;*/
    /*border-color: #d71f0f !important;*/
}

.ant-image-preview-operations {
    color: white;
}
.ant-image-preview-switch-left, .ant-image-preview-switch-right {
    color: rgba(255, 255, 255, 0.79);
}


.app-shipping .ant-tabs-tab-btn:active,
.app-shipping .ant-tabs-tab-btn:focus,
.app-shipping .ant-tabs-tab-remove:active,
.app-shipping .ant-tabs-tab-remove:focus,
.app-shipping .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:active,
.app-shipping .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:focus,
.app-shipping .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:active,
.app-shipping .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:focus {
    color: #d71f0f;
}

.ant-popover .ant-btn-link {
    color: #d71f0f;
}


.app-shipping .ant-menu-item a:hover,
.app-shipping .ant-menu-item > .ant-badge a:hover,
.app-shipping .ant-menu-submenu-selected {
    color: #d71f0fe8;
}


.app-shipping .ant-menu-item-active,
.app-shipping .ant-menu-item:hover,
.app-shipping .ant-menu-submenu-active,
.app-shipping .ant-menu-submenu-title:hover,
.app-shipping .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
    color: #d71f0f;
}


.app-shipping .ant-menu-item-selected,
.app-shipping .ant-menu-item-selected a,
.app-shipping .ant-menu-item-selected a:hover,
.app-shipping .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.app-shipping .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon {
    color: #d71f0f;
}


.app-shipping .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: rgba(247, 62, 62, .1);
}


.app-shipping .ant-menu-item:active,
.app-shipping .ant-menu-submenu-title:active {
    background-color: rgba(247, 62, 62, .1);
}
.app-shipping .ant-menu-horizontal > .ant-menu-item-active,
.app-shipping .ant-menu-horizontal > .ant-menu-item:hover,
.app-shipping .ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
    background-color: transparent;
}


.app-shipping .ant-menu-inline .ant-menu-item::after,
.app-shipping .ant-menu-vertical .ant-menu-item::after,
.app-shipping .ant-menu-vertical-left .ant-menu-item::after,
.app-shipping .ant-menu-vertical-right .ant-menu-item::after {
    border-right: 3px solid #d71f0f;
}

.app-shipping .ant-btn-primary {
    background-color: #d71f0f;
    border-color: #d71f0f;
}

.app-shipping .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: #d71f0f;
}
.app-shipping .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    color: #d71f0f;
}

.app-shipping .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: rgba(247, 62, 62, .1);
}
.app-shipping .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: rgba(247, 62, 62, .1);
}
.app-shipping .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #d71f0f;
}
.app-shipping .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #d71f0f;
    box-shadow: rgba(247, 62, 62, .2) 0 0 0 2px;
}

.app-shipping .ant-input-number-focused,
.app-shipping .ant-input-number:hover {
    border-color: #d71f0f;
}
.app-shipping .ant-input-number-focused,
.app-shipping .ant-input-number:focus {
    border-color: #d71f0f;
    box-shadow: rgba(247, 62, 62, .2) 0 0 0 2px;
}

.app-shipping .ant-picker-focused,
.app-shipping .ant-picker:hover {
    border-color: #d71f0f;
}
.app-shipping .ant-picker-focused {
    box-shadow: rgba(247, 62, 62, .2) 0 0 0 2px;
}

.app-shipping .ant-input:hover {
    border-color: #d71f0f;
}
.app-shipping .ant-input-focused,
.app-shipping .ant-input:focus {
    border-color: #d71f0f;
    box-shadow: rgba(247, 62, 62, .2) 0 0 0 2px;
}


.app-shipping .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #d71f0f;

}
.app-shipping .ant-input-affix-wrapper-focused,
.app-shipping .ant-input-affix-wrapper:focus {
    border-color: #d71f0f;
    box-shadow: rgba(247, 62, 62, .2) 0 0 0 2px;
}


.app-shipping .ant-pagination-next:focus-visible .ant-pagination-item-link,
.app-shipping .ant-pagination-next:hover .ant-pagination-item-link,
.app-shipping .ant-pagination-prev:focus-visible .ant-pagination-item-link,
.app-shipping .ant-pagination-prev:hover .ant-pagination-item-link {
    color: #d71f0f;
    border-color: #d71f0f;
}

.app-shipping .ant-btn-primary:focus,
.app-shipping .ant-btn-primary:hover {
    color: #fff;
    background: #d71f0f;
    border-color: #d71f0f;
}

.app-shipping .ant-pagination-item:focus-visible a,
.app-shipping .ant-pagination-item:hover a {
    color: #d71f0f;
}

.app-shipping .ant-input-group-addon .ant-select-focused .ant-select-selector,
.app-shipping .ant-input-group-addon .ant-select-open .ant-select-selector,
.app-shipping .ant-list-item-meta-title > a:hover {
    color: #d71f0f;
}


.app-shipping .ant-checkbox-checked .ant-checkbox-inner {
    color: #d71f0f;
    background: #d71f0f;
    border-color: #d71f0f;

}
.app-shipping .ant-checkbox-input:focus + .ant-checkbox-inner,
.app-shipping .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.app-shipping .ant-checkbox:hover .ant-checkbox-inner {
    border-color: #d71f0f;
}
.app-shipping .ant-checkbox-checked::after {
    border-color: #d71f0f;
}

.app-shipping .ant-btn-primary[disabled],
.app-shipping .ant-btn-primary[disabled]:active,
.app-shipping .ant-btn-primary[disabled]:focus,
.app-shipping .ant-btn-primary[disabled]:hover {
    color: rgb(208, 212, 215);
    background: rgb(247, 247, 248);
    border-color: rgb(230, 235, 241);
}


.app-shipping .ant-card-head-title {
    font-weight: 500;
}

.app-shipping .ant-table-thead > tr > th {
    border-bottom: 1px solid #d71f0f;
    font-weight: 500;
}

.app-shipping .ant-btn-primary {
    background-color: transparent;
    border-color: #d71f0f;
    color: #d71f0f;
}


.video-container {
    height: 100vh;
}
.video-container > div {
    height: 100vh;
}

.app-shipping .pr--10 {
    padding-right: 10px;
}
.app-shipping .pr--5 {
    padding-right: 5px;
}
.app-shipping .text-center {
    text-align: center;
}
.app-shipping .text--left {
    /*text-align: unset;*/
}
.app-shipping .mb---8 {
    /*margin-bottom: initial;*/
}
.filter-img {
    margin-bottom: 24px;
}
@media screen and (max-width: 768px) {
    .app-shipping .app-content {
        padding-left: 8px;
        padding-right: 8px;
    }


    .app-shipping .d-xs-none {
        display: none;
    }
    .app-shipping .h-card-shipping-item .h-vin-number {
        margin-right: 0!important;
    }

    .app-shipping .pr--10 {
        padding-right: 0;
    }
    .app-shipping .pr--5 {
        padding-right: 0;
    }
    .app-shipping .text-center.text--left {
        text-align: left !important;
    }
    .app-shipping .text--left {
        text-align: left;
    }
    .app-shipping .mb---8 {
        margin-bottom: -8px;
    }

    .app-shipping .payments-item-form .ant-row.mb--0 {
        margin-bottom: 0;
    }
    .app-shipping .payments-item-form .ant-row.mb---8 {
        margin-bottom: -8px;
    }

    .invoices-item.invoices-item-buttons .ant-card-extra {
        display: flex;
        /*margin-left: -8px;*/
        justify-content: space-around;
        width: 100%;
        min-width: 100%;
    }
    .invoices-item.invoices-item-buttons .ant-btn {
        margin-left: 0;
    }
    .invoices-item.invoices-item-buttons .ant-btn:last-child {
        margin-left: 10px;
    }

    .invoices-item .ant-card-head {
        /*padding-left: 5px;*/
        /*padding-right: 5px;*/
    }
    .invoices-item .ant-card-head-wrapper {
        flex-direction: column;
        align-items: start;
    }
    .filter-img {
        margin-bottom: 30px;
        margin-left: 5px;
    }

}

.w--100 {
    width: auto;
}

@media (max-width: 575px) {

    .ant-form .xs-inline .ant-form-item .ant-form-item-control,
    .xs-inline .ant-form .ant-form-item .ant-form-item-control {
        flex: 0 0 60%;
        max-width: 100%;
    }
    .ant-form .xs-inline .ant-form-item .ant-form-item-label,
    .xs-inline .ant-form .ant-form-item .ant-form-item-label {
        flex: 0 0 40%;
        max-width: 100%;
    }

    .ant-form .xs-inline .ant-form-item.total-sum .ant-form-item-control {
        flex: 0 0 50%;
        max-width: 100%;
    }
    .ant-form .xs-inline .ant-form-item.total-sum .ant-form-item-label {
        flex: 0 0 50%;
        max-width: 100%;
    }

    .ant-form .export-row.xs-inline .ant-form-item .ant-form-item-control {
        flex: 0 0 20%;
        max-width: 100%;
    }
    .ant-form .export-row.xs-inline .ant-form-item .ant-form-item-label {
        flex: 0 0 80%;
        max-width: 100%;
    }


    .w--100 {
        width: 100%;
    }

}

@media screen and (max-width: 991px) {

    .app-shipping .app-content {
        margin-bottom: 60px;
    }
    .app-shipping .footer {
        z-index: 10;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        min-width: 100%;
        margin: 0 0;
        background-color: #ffffff;
        color: rgb(26, 51, 83);
        flex-direction: row;
        justify-content: space-around;
    }
    .app-shipping .footer a {
        color: rgb(69, 85, 96);
        font-size: 1.3rem;
        display: flex;
        flex-direction: column;
    }
    .app-shipping .footer a.menu-item-selected {
        color: #d71f0f;
    }
    .app-shipping .footer a.menu-item-selected .ant-badge {
        color: #d71f0f;
    }
    .app-shipping .footer a span {
        font-size: 13px;
    }
    .app-shipping .footer a .ant-badge {
        display: flex;
        flex-direction: column;
    }
    .app-shipping .footer a .ant-badge span.anticon {
        margin-bottom: 2px;
    }
    .app-shipping .footer a span.anticon svg {
        width: 25px;
        /*margin-bottom: -5px;*/
    }


    .app-shipping .footer a .ant-badge sup.ant-badge-count {
        right: 18px;
        height: 12px;
        min-width: 10px;
        border-radius: 12px;
        line-height: 1;
        padding: 0 3px;
    }
    .app-shipping .footer a .ant-badge sup.ant-badge-count .ant-scroll-number-only {
        height: auto;
    }
    .app-shipping .footer a .ant-badge sup.ant-badge-count p {
        line-height: 1;
        font-size: 10px;
    }
    .footer .ant-scroll-number-only > p.ant-scroll-number-only-unit {
        height: auto;
    }

}