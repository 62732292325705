.questionnaire-modal .ant-modal-title {
    color: #d71f0f;
    text-align: center;
}

.questionnaire-modal .ant-modal-body {
    padding: 25px 25px;
}

.questionnaire-modal .ant-modal-header {
    border-bottom-color: #d71f0f;
}

.questionnaire-modal .ant-modal-title {
    font-size: 1.1rem;
}
.questionnaire-modal .ant-modal-bottom-title {
    color: #d71f0f;
    text-align: center;
    font-size: .9rem;
    margin-bottom: 0;
}
.questionnaire-modal .ant-modal-footer {
    border-top-color: #d71f0f;
}
.questionnaire-modal .ant-modal-close {
    color: #d71f0f;
}

.questionnaire-modal .ant-slider-track {
    background-color: #d71f0f;
}
.questionnaire-modal .ant-slider:hover .ant-slider-track {
    background-color: #d71f0f;
}

.questionnaire-modal .ant-slider-handle {
    border-color: #d71f0f;
}

.questionnaire-modal .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open),
.questionnaire-modal .ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-icon {
    border-color: #d71f0f;
}

.questionnaire-modal .ant-slider-handle:focus {
    box-shadow: rgba(215, 31, 15, 0.12) 0 0 0 5px;
}

.questionnaire-modal .ant-input-number-handler-wrap {
    visibility: hidden;
}
.questionnaire-modal .rating .ant-input-number-input {
    text-align: center;
}
