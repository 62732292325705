
.birthday-card-modal .ant-modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}
.birthday-card-modal .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
}
.birthday-card-modal .ant-modal-close {
    display: none;
}

.birthday-card-modal .birthdayCard {
    position: relative;
    width: 400px;
    height: 566px;
    cursor: pointer;
    transform-style: preserve-3d;
    transform: perspective(4000px);
    transition: 1s;
}

.birthday-card-modal .birthdayCard:hover {
    transform: perspective(4000px) rotate(5deg);
    box-shadow: inset 100px 20px 100px rgba(0, 0, 0, 0.2), 0 10px 100px rgba(0, 0, 0, 0.5);
}

.birthday-card-modal .birthdayCard:hover .cardFront {
    transform: rotateY(-160deg);
}


.birthday-card-modal .cardFront {
    position: relative;
    background-color: #000;
    width: 400px;
    height: 566px;
    overflow: hidden;
    transform-origin: left;
    box-shadow: inset 100px 20px 100px rgba(0, 0, 0, 0.2), 30px 0 50px rgba(0, 0, 0, 0.4);
    transition: 0.6s;
}


.birthday-card-modal .cardInside {
    position: absolute;
    background-color: #fff;
    width: 400px;
    height: 566px;
    z-index: -1;
    left: 0;
    top: 0;
    box-shadow: inset 100px 20px 100px rgba(0, 0, 0, 0.2);
}

.birthday-card-modal p {
    /*font-family: "Brush Script MT", cursive;*/
    font-size: 16px;
    margin: 12px;
    color: #333;
}

.greeting-content {
    /*font-family: "Brush Script MT", cursive;*/
    font-size: 20px;
    margin: 40px;
    color: #333;
}

.birthday-card-modal .name {
    position: absolute;
    left: 150px;
    top: 200px;
    color: #333;
}

.birthday-card-modal .back {
    font-family: Tahoma, sans-serif;
    color: #333;
    text-align: center;
    margin: 30px;
    outline-color: #333;
}



.birthday-card-modal .ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 3em
}

.birthday-card-modal .ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 3em
}

.birthday-card-modal .ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 6em
}

.birthday-card-modal .ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 6em
}

.birthday-card-modal .ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 9em
}

.birthday-card-modal .ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 9em
}

.birthday-card-modal .ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 12em
}

.birthday-card-modal .ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 12em
}

.birthday-card-modal .ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 15em
}

.birthday-card-modal .ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 15em
}

.birthday-card-modal .ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 18em
}

.birthday-card-modal .ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 18em
}

.birthday-card-modal .ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 21em
}

.birthday-card-modal .ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 21em
}

.birthday-card-modal .ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 24em
}

.birthday-card-modal .ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 24em
}

.birthday-card-modal .ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 27em
}

.birthday-card-modal .ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 27em
}
